<template>
  <layout name="Plain">
    <transition name="fade" mode="out-in">
      <router-view class="InformationPagesIndex" />
    </transition>
  </layout>
</template>

<script>
import { mapActions } from 'vuex'

/**
 * @module InformationPagesIndex
 */
export default {
  name: 'InformationPagesIndex',
  mounted () {
    this.fetchPlans()
  },

  methods: {
    ...mapActions('cx/payment', ['fetchPlans'])
  }
}
</script>
